export const STRING = {
  NOTIFICATION_TEMPLATE_NAME: ' Template Name', //suffix
  NEXT_PMS_CHECKIN_DATE: ' Next PMS Checkin Date Notification', //suffix
  CONVERSION_LOG_DAY_NOTIFICATION: (question, day) => `Conversion Log ${question} Day ${day} Notification`,
  SHOW_DETAILS: (is_show_details) => (is_show_details ? `Hide Details` : `Show Details`),
  TEAM_APPOINTMENT_FEE: 'Appointment Fee',
  TEAM_WORKING_HOURS_AND_APPOINTMENTS: 'Working Hours & Appointments',
  TEAM_CREADENTIALS: 'Credentials',
  TEAM_PERSONAL_INFORMATION: 'Personal Information',
  TEAM_ZOHO_DETAILS: 'Zoho Details',
  DOCTOR_ROLE: 'doctor',
  HEALTH_COACH_LEAD_ROLE: 'health_coach_lead',
  REVENUE_MANAGER_ROLE: 'revenue_manager',
  DOCTOR_PMS_ROLE: 'doctor_pms',
  HEALTH_COACH_ROLE: 'health_coach',
  RUPEE: (rs) => `₹ ${rs}`,
  DOCTOR_ROLE: 'doctor',
  PHYSICAL: 'Physical',
  VIRTUAL: 'Virtual',
  APPOINTMENT_MODE_LIST: [
    { id: 'physical', title: 'Physical' },
    // { id: 'virtual', title: 'Virtual' },
  ],
};
